import React, { useState } from 'react'
import _ from 'lodash'

import parseCSV from './helpers/parseSurvey'

import Report from './Report'
import SettingsForm from './SettingsForm'

const csv = `06. Doelgroep;Welke kenmerken zijn van toepassing op de cliënten die je begeleidt?;multiple options;"Jongeren,Ouderen,Gezinnen met kinderen,Migratieachtergrond,Multiproblematiek,Lager opgeleid,Hoger opgeleid,Lager inkomen,Hoger inkomen,Mindere taalbeheersing,(Licht) verstandelijke beperking,Psychiatrische problematiek,Schuldensituatie,Huisvestingsproblematiek,Zorgmijders,""Autisme, ADHD, etc."",Problemen in de huiselijke sfeer,Ondernemers,Anders: klik hier om je antwoord in te vullen"
07. Doelgroep misc;Andere kenmerken van de doelgroep;open;
08. Deel finprob;Welk deel van je cliënten kampt met financiële problemen?;single option;0% - 25%,25% - 50%,50% - 75%,75% - 100%
09. Doelgroep finprob;Welke kenmerken zijn specifiek van toepassing op de groep cliënten met financiële problemen?;multiple options;"Jongeren,Ouderen,Gezinnen met kinderen,Migratieachtergrond,Multiproblematiek,Lager opgeleid,Hoger opgeleid,Lager inkomen,Hoger inkomen,Mindere taalbeheersing,(Licht) verstandelijke beperking,Psychiatrische problematiek,Schuldensituatie,Huisvestingsproblematiek,Zorgmijders,""Autisme, ADHD, etc."",Problemen in de huiselijke sfeer,Ondernemers,Anders: klik hier om je antwoord in te vullen"
10. Doelgroep finprob misc;Andere kenmerken van de doelgroep met financiële problematiek;open;
11. Toeleiding;Hoe komen je cliënten bij jou terecht?;multiple options;Reactief: ik word door cliënten zelf benaderd,Doorverwijzing: cliënten worden door andere partijen naar mij toegeleid,Proactief: ik benader zelf cliënten
12. Tijdsbesteding;Welk deel van je tijd besteed je met of voor je cliënten aan hun administratie en financiën?;single option;0% - 25%,25% - 50%,50% - 75%,75% - 100%
13. Mening tijdsbesteding;Wat vind je hiervan, in verhouding tot andere leefgebieden?;single option;Te weinig: ik zou hier meer tijd aan willen besteden,Dit is precies goed,Te veel: ik zou hier minder tijd aan willen besteden,Geen mening
14. Doorverwijzing;Wanneer verwijs of leid je een cliënt door naar een financieel specialist?;multiple options;Zodra een vaste last niet meer betaald wordt,Volgens een vast protocol of vaste werkwijze,Als aflossing van schulden niet haalbaar is binnen 3 jaar,Bij een crisissituatie zoals een dreigende huisuitzetting,Als er sprake is van multiproblematiek,Bij onvoldoende leerbaarheid van cliënt,Als het aantal schuldeisers een bepaald aantal overstijgt,Als de totale schuld een bepaald bedrag overschrijdt,Als ik onvoldoende hulpmiddelen heb,Zodra er contact gelegd moet worden met schuldeisers,Als de problematiek te complex wordt,Als dit teveel tijd kost,Wanneer de schuldenlast onduidelijk is,Zodra het probleem in kaart is gebracht,Anders: klik hier om je antwoord in te vullen
15. Doorverwijzing misc;Andere momenten van doorverwijzen;open;
16. Stelling finprob;Stelling: financiële problemen staan begeleiding op andere leefdomeinen vaak in de weg;single option;Ja,Nee
17. Drijfveren;Met welke reden(en) begeleid je je cliënten bij administratie en geldzaken?;multiple options;Ik vind het leuk om te doen,Het staat in mijn taakomschrijving/opdracht,Het is een noodzakelijk onderdeel van de begeleiding,Anders: klik hier om je antwoord in te vullen
18. Drijfveren misc;Andere reden;open;
19. Deelgebied 1;Deelgebied 1: Overzicht en organiseren;multiple options;"Budgetplan maken/aanpassen,Administratie ordenen,Schuldenoverzicht maken,Financieel probleem inventariseren/constateren,""Digitale middelen leren gebruiken (bijv. online bankieren, Tikkie, DigID, iDEAL)"",Plan van aanpak financiën opstellen,""Drierekeningenstelsel doorvoeren (vaste lasten, huishoudgeld, spaarpotje)"",Anders: klik hier om je antwoord in te vullen"
20. Deelgebied 1 misc;Andere activiteiten m.b.t. overzicht en organiseren;open;
21. Deelgebied 2;Deelgebied 2: Inkomen en voorzieningen;multiple options;"Proefberekeningen maken,Hulp bij toeslagen,""Hulp bij voorzieningen (o.a. voedselbank, fondsen)"",Hulp bij aangiften,Bezwaar maken,Hulp bij werk of uitkering,Anders: klik hier om je antwoord in te vullen"
22. Deelgebied 2 misc;Andere activiteiten m.b.t. inkomen en voorzieningen;open;
23. Deelgebied 3;Deelgebied 3: Verantwoord besteden;multiple options;Coachen op omgaan met geld,In de gaten houden van financieel gedrag,Samen budgetten beheren,Juist prioriteren van uitgaven,Samen geld overmaken,Anders: klik hier om je antwoord in te vullen
24. Deelgebied 3 misc;Andere activiteiten m.b.t. verantwoord besteden;open;
25. Deelgebied 4;Deelgebied 4: Contact met schuldeisers;multiple options;Kwijtschelding aanvragen,Betalingsregeling(en) treffen,Uitstel van betaling aanvragen,Anders: klik hier om je antwoord in te vullen
26. Deelgebied 4 misc;Andere activiteiten m.b.t. contact met schuldeisers;open;
27. Deelgebied 5;Deelgebied 5: Doorverwijzing vervolgtraject;multiple options;Doorverwijzen naar intern hulpaanbod,Doorverwijzen naar schuldhulp,Documenten verzamelen voor schuldhulp,Doorverwijzen naar budgetbeheer,Documenten verzamelen voor budgetbeheer,Doorverwijzen naar bewindvoering,Anders: klik hier om je antwoord in te vullen
28. Deelgebied 5 misc;Andere activiteiten m.b.t. doorverwijzing vervolgtraject;open;
29. Taakomschrijving;Staat begeleiding bij administratie en financiën in jouw taakomschrijving?;single option;"Nee,""Ja, het wordt genoemd"",""Ja, het wordt omschreven"",""Ja, ik heb hier doelstellingen voor"""
30. Ondersteuning;Biedt je werkgever je scholing op het gebied van financiën en administratie?;single option;"""Ja, mijn werkgever biedt proactief training aan"",""Ja, ik kan op eigen initiatief via mijn werkgever trainingen volgen"",Nee,Weet ik niet"
31. Kennis;Waar haal jij je eigen kennis en vaardigheden vandaan?;multiple options;"Uit mijn opleiding,Vanuit praktijkervaring,""Uit cursussen, training of bijscholing"",Uit mijn netwerk,Van internet,Anders: klik hier om je antwoord in te vullen"
32. Kennis misc;Andere bron van kennis en vaardigheden;open;
33. Aanspreekpunt;Is er binnen je organisatie een gespecialiseerd aanspreekpunt voor financiële vraagstukken?;single option;Ja,Nee,Weet ik niet
34. Toegerust;Voel je je voldoende toegerust om je cliënt te ondersteunen bij financiën en administratie?;single option;Ja,Nee
35. Opmerkingen;Stel, je zou één ding kunnen veranderen als het gaat om begeleiding bij administratie en financiën. Wat zou dat zijn?;open;`

const App = () => {
    const [settings, setSettings] = useState()
    const [data, setData] = useState()

    const onSubmit = (newSettings, newFileResult) => {
        setSettings(newSettings)

        if (!newFileResult) {
            alert('Please select a valid file')

            return
        }

        const orgFilter = _.get(newSettings, 'organization')
        const profFilter = _.get(newSettings, 'profession')

        // Filter by organization and/or profession
        const newData = _.get(newFileResult, 'data') || []
        const filteredByOrgData = orgFilter
            ? _.filter(newData, d => _.get(d, '03. Organisatie') === orgFilter)
            : newData
        const filteredByProfData = profFilter
            ? _.filter(filteredByOrgData, d => _.get(d, '04. Functie') === profFilter)
            : filteredByOrgData

        setData(filteredByProfData)
        document.title = `Inzichten startmeting ${orgFilter || 'alle respondenten'} ${profFilter ? `(${profFilter})` : ''}`
    }

    return (
        <div>
            <SettingsForm onSubmit={onSubmit} />
            <Report settings={settings} results={data} survey={parseCSV(csv)} />
        </div>
    )
}

export default App
