import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import _ from 'lodash'
import { theme } from './styles/plinkrTheme'
import GlobalFonts from './styles/globalFonts'

import FrontPage from './pages/FrontPage'
import IntroPage from './pages/IntroPage'
import TargetAudiencePage from './pages/TargetAudiencePage'
import MotivationPage from './pages/MotivationPage'
import ActivityPageOne from './pages/ActivityPageOne'
import ActivityPageTwo from './pages/ActivityPageTwo'
import DescriptionPage from './pages/DescriptionPage'
import DescriptionPageTwo from './pages/DescriptionPageTwo'
import ReviewPage from './pages/ReviewPage'

import useChartData from './components/useChartData'
import { useEffect } from 'react'
import { parseRow } from './helpers/parseSurvey'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 5vh;
`

const Report = ({ settings, results, survey }) => {
  const [{ setState }, state] = useChartData()
  const [categories, setCategories] = useState({})

  const [hasData, setHasData] = useState(false)

  const [respondents, setRespondents] = useState()
  const [dates, setDates] = useState()

  const reviewData = []
  const [reviews, setReviews] = useState()

  const generateBarData = (type, resultsIndex, surveyIndex, omit = ['']) => {
    const arr = []
    results.forEach(object => {
      const key = Object.keys(object)[resultsIndex]
      parseRow(object[key], true).forEach(x => { arr.push(x) })
    })

    let possibleAnswers = survey[surveyIndex].answers

    let answerCounts = arr.reduce((counts, answer) => {
      const parsedAnswer = answer.trim().replace(/^[\\"]+|[\\"]+$/g, '')
      counts[parsedAnswer] = (counts[parsedAnswer] || 0) + 1
      return counts
    }, {})

    answerCounts = _.omit(answerCounts, omit)
    possibleAnswers = _.remove(possibleAnswers, item => !omit.includes(item))

    const counts = possibleAnswers.map(answer => answerCounts[answer] || 0);

    setState({ type, data: counts })
    setCategories(v => ({ ...v, [type]: _.map(possibleAnswers, item => item.startsWith('Anders:') ? 'Anders' : item) }))
  }

  useEffect(() => {
    if (settings && results) {
      setHasData(true)
      setRespondents(results.length)

      const getValue = (part, o) => Object.entries(o).find(([k, v]) => k.startsWith(part))?.[1]
      const startDate = getValue('02. Created', results[0]).slice(0, 9)
      const endDate = getValue('02. Created', results[results.length - 1]).slice(0, 9)
      setDates(startDate + ' t/m ' + endDate)

      generateBarData('TARGET_DONUT', 8, 1)
      generateBarData('MOTIVATION_BAR', 17, 8)
      generateBarData('MOTIVATION_DONUT', 16, 7)
      generateBarData('DOUBLE_BAR', 6, 0)
      generateBarData('ACTIVITY_ONE_BAR', 19, 9)
      generateBarData('ACTIVITY_TWO_BAR', 21, 10)
      generateBarData('ACTIVITY_THREE_BAR', 23, 11)
      generateBarData('ACTIVITY_FOUR_BAR', 25, 12)
      generateBarData('ACTIVITY_FIVE_BAR', 27, 13)
      generateBarData('ACTIVITY_SIX_BAR', 14, 6)
      generateBarData('DESCRIPTION_BAR', 31, 16)

      // GETTING RESULTS 35TH ROW
      results.forEach(function (object) {
        const key = Object.keys(object)[35]
        reviewData.push(object[key])
      })

      setReviews(reviewData.filter(n => n && n.length > 1))
    }
  }, [settings, results]) // eslint-disable-line

  const chunkedComments = _.chunk(reviews, 8)

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <GlobalFonts />

        {hasData &&
          <>
            <FrontPage respondents={respondents} dates={dates} clientName={_.get(settings, 'name')} organizationName={_.get(settings, 'organization')} professionName={_.get(settings, 'profession')} />
            <IntroPage page={"Pagina 1 van " + (7 + chunkedComments.length)} introduction={_.get(settings, 'introduction')} />
            <TargetAudiencePage
              respondents={respondents}
              barCategories={categories.DOUBLE_BAR}
              data={state.DOUBLE_BAR}
              donutCategories={categories.TARGET_DONUT}
              donutSeries={state.TARGET_DONUT}
              page={"Pagina 2 van " + (7 + chunkedComments.length)}
            />
            <MotivationPage
              results={results}
              respondents={respondents}
              barCategories={categories.MOTIVATION_BAR}
              barSeries={state.MOTIVATION_BAR}
              donutCategories={categories.MOTIVATION_DONUT}
              donutSeries={state.MOTIVATION_DONUT}
              page={"Pagina 3 van " + (7 + chunkedComments.length)}
            />
            <ActivityPageOne
              respondents={respondents}
              page={"Pagina 4 van " + (7 + chunkedComments.length)}
              text="Er zijn verschillende deelgebieden waarop begeleiding bij administratie en financiën kan plaatsvinden. We hebben per deelgebied gevraagd om aan te geven of hierin begeleiding wordt gegeven."
              barOneTitle="Deelgebied 1: Overzicht en organiseren"
              barOneCategories={categories.ACTIVITY_ONE_BAR}
              barOneSeries={state.ACTIVITY_ONE_BAR}
              barOneColor={'#3A416B'}
              barTwoTitle="Deelgebied 2: Inkomen en voorzieningen"
              barTwoCategories={categories.ACTIVITY_TWO_BAR}
              barTwoSeries={state.ACTIVITY_TWO_BAR}
              barTwoColor={'#3BA4B1'}
              barThreeTitle="Deelgebied 3: Verantwoord besteden"
              barThreeCategories={categories.ACTIVITY_THREE_BAR}
              barThreeSeries={state.ACTIVITY_THREE_BAR}
              barThreeColor={'#916547'}
              barFourTitle="Deelgebied 4: Contact met schuldeisers"
              barFourCategories={categories.ACTIVITY_FOUR_BAR}
              barFourSeries={state.ACTIVITY_FOUR_BAR}
              barFourColor={'#FC7773'}
            />
            <ActivityPageTwo
              respondents={respondents}
              page={"Pagina 5 van " + (7 + chunkedComments.length)}
              barFiveTitle="Deelgebied 5: Doorverwijzing vervolgtraject"
              barFiveCategories={categories.ACTIVITY_FIVE_BAR}
              barFiveSeries={state.ACTIVITY_FIVE_BAR}
              barFiveColor={'#131C4E'}
              barTwoTitle="Wanneer verwijs of leid je iemand door naar een financieel specialist?"
              barTwoCategories={categories.ACTIVITY_SIX_BAR}
              barTwoSeries={state.ACTIVITY_SIX_BAR}
              barTwoColor={'#3BA4B1'}
            />
            <DescriptionPage
              page={"Pagina 6 van " + (7 + chunkedComments.length)}
              results={results}
            />
            <DescriptionPageTwo
              respondents={respondents}
              page={"Pagina 7 van " + (7 + chunkedComments.length)}
              barCategories={categories.DESCRIPTION_BAR}
              barSeries={state.DESCRIPTION_BAR}
              barColor={'#3BA4B1'}
            />
            {reviews.length > 8
              ? chunkedComments.map((chunk, index) => <ReviewPage key={index} page={"Pagina " + (8 + index) + " van " + (7 + chunkedComments.length)} data={chunk} />)
              : <ReviewPage
                  page="Pagina 8 van 8"
                  data={reviews && reviews}
                />}
          </>
        }
      </Container>
    </ThemeProvider>
  )
}

export default Report

