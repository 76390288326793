import Chart from 'react-apexcharts'
import React from 'react'

const ColumnChart = (props) => {
    const columnOptions = {
            chart: {
                height: 100,
                type: 'bar',
                toolbar: {
                    show: false
                },
                parentHeightOffset: 0,
              },
              dataLabels: {
                enabled: false
              },
              colors: props.colors,
              plotOptions: {
                bar: {
                  columnWidth: '45%',
                  distributed: false,
                }
              },
              grid: {
                padding: {
                 left: 0,
                 right: 0
                }
              },
              legend: {
                show: false
              },
              xaxis: {
                show: true,
                categories: props.categories,
                labels: {
                    show: true,
                    trim: false,
                    rotate: 0,
                    style: {
                        marginTop: '-100px',
                        fontSize: '8px',
                        fontFamily: 'Karla',
                        fontWeight: 400,
                    },
                },
                axisBorder: {
                    show: false,
                }
              },
              yaxis: {
                show: false,
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false,
                }
              },
    }
    const series = [{
        data: props.series 
      }]

    return (
        props.categories &&
            <Chart options={columnOptions} series={series} type="bar" width='100%' height={props.height || '90px'} />
    )
}


export default ColumnChart