import Chart from 'react-apexcharts'
import React from 'react'

const DonutChart = ({ height, bottomLegend, donutCategories, donutSeries }) => {
  const dict = []
  
  donutCategories.forEach((cat, index) => dict.push([cat, donutSeries[index]]))
  dict.sort((a, b) => b[0].localeCompare(a[0]))

  const sortedCategories = dict.map(v => v[0]) 
  const sortedValues = dict.map(v => v[1]) 

  const DonutOptions =  {
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return Math.trunc(val) + '%'
      },
      style: {
        fontFamily: 'Karla',
        fontSize: '10px',
      },
    },
    legend: {
      fontFamily: "Karla",
      position: bottomLegend ? 'bottom' : 'right',
      inverseOrder: true,
      horizontalAlign: 'center', 
      fontSize: '10px',
      itemMargin: {
        horizontal: 0,
        vertical: 10
    },
    },
    labels: sortedCategories || [],
    colors: sortedCategories && sortedCategories.length < 3 ? ['#3BA4B1', '#FC7773'] : ['#131C4E', '#4DCCC4',  '#C0926C', '#FC7773']
  }
  
  return (
    sortedValues && <Chart options={DonutOptions} series={sortedValues} type="donut" width='100%' height={height || '400px'} />
  )
}

export default DonutChart
