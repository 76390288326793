import React from 'react'
import _ from 'lodash'
import Footer from '../components/Footer'

import styled from 'styled-components'
import DonutChart from '../charts/DonutChart'

const Page = styled.div`
  width: 210mm;
  min-height: 294mm;
  position: relative;
  page-break-before: always;
`

const Container = styled.div`
  margin: 0 auto;
  width: 80%;
  position: relative;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const HeaderBackgroundColor = styled.div`
  background-color: #131C4E;
  width: 100%;
  margin-bottom: 30px;
  padding-top: 2px;
`

const StyledSmallHeaderText = styled.p`
  ${props => props.theme.fontStyle.euro};
  font-weight: 300;
  font-size: 18px;
  color: #fff;
  margin-left: 40px;
  display: inline;
`

const StyledSubHeaderText = styled.h3`
  ${props => props.theme.fontStyle.h3};
  font-size: 16px;
  color: #131C4E;
  text-align: center;
  height: 40px;
  margin-bottom: 20px;
`

const DonutContainer = styled.div`
  width: 45%;
  max-height: 400px;
  margin-bottom: 50px;
`

const DescriptionDonut = ({data, header}) => {
  const counts = _.countBy(data)

  return (
    <DonutContainer>
        <StyledSubHeaderText>{header}</StyledSubHeaderText>
        <DonutChart donutCategories={_.keys(counts)} donutSeries={_.values(counts)} bottomLegend />
      </DonutContainer>
  )
}

const getResultsForRow = (results, columnName) => {
  return _.map(results, result => _.get(result, columnName))
}

const DescriptionPage = ({ page, results }) =>  (
  <Page>
    <Container>
      <HeaderBackgroundColor><StyledSmallHeaderText>taakomschrijving en ondersteuning (1/2)</StyledSmallHeaderText></HeaderBackgroundColor>
      <FlexContainer>
        <DescriptionDonut
          data={getResultsForRow(results, '29. Taakomschrijving')}
          header="Staat begeleiding bij administratie en financiën in jouw taakomschrijving?"
        />
        <DescriptionDonut
          data={getResultsForRow(results, '34. Toegerust')}
          header="Voel je je voldoende toegerust om je klant te ondersteunen bij financiën en administratie?"
        />
      </FlexContainer>

      <FlexContainer>
      <DescriptionDonut
          data={getResultsForRow(results, '30. Ondersteuning')}
          header="Biedt je werkgever je training op het gebied van financiële begeleiding?"
        />
        <DescriptionDonut
          data={getResultsForRow(results, '33. Aanspreekpunt')}
          header="Is er binnen je organisatie een gespecialiseerd aanspreekpunt voor financiële vraagstukken?"
        />
      </FlexContainer>
    </Container>

    <Footer page={page}/>
  </Page>
)

export default DescriptionPage