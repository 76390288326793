import React, { useState } from 'react'
import Papa from 'papaparse'
import _ from 'lodash'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useEffect } from 'react';

// const DEFAULT_INTRODUCTION = `Deze rapportage is gegenereerd met data uit werkelijke nulmetingen, uitgevoerd in september/oktober 2021. Plinkr heeft uitsluitend de volgende bewerkingen gedaan op de oorspronkelijke data: <ul><li>het anonimiseren van herleidbare gegevens (o.a. verwijderen van organisatienamen); </li><li>het verbeteren van typ- en interpunctiefouten in de antwoorden op de open vragen; </li><li>het verwijderen van antwoorden op de open vragen, die specifiek en herleidbaar gaan over de eigen organisatie en niet relevant zijn in algemene zin. </li></ul>Alle hulpverleners uit deze rapportage begeleiden cliënten op meerdere leefdomeinen, waaronder financiën. Zij hebben hulp bij geldzaken en administratie in hun taakomschrijving staan.</p>`
const DEFAULT_INTRODUCTION = `Deze rapportage is gegenereerd met data uit startmetingen, uitgevoerd tussen XX en XX bij XXX. Deze metingen zijn uitgevoerd in opdracht van XXX. De rapportage geeft inzicht in de doelgroepen, drijfveren, activiteiten en ondersteuning van hulpverleners die zich -in meer of mindere mate- bezighouden met hulpverlening bij geldzaken en administratie.

Plinkr heeft uitsluitend de volgende bewerkingen gedaan op de oorspronkelijke data:

<ul><li>het anonimiseren van naar personen herleidbare gegevens;</li><li>

    het verwijderen van antwoorden op de open vragen, die specifiek en herleidbaar gaan over personen.</li></ul><br>
De hulpverleners uit deze rapportage begeleiden inwoners op meerdere leefdomeinen, waaronder financiën. Zij hebben hulp bij geldzaken en administratie in principe in hun taakomschrijving staan.`

const SettingsForm = ({ onSubmit }) => {
    const [name, setName] = useState('')
    const [introduction, setIntroduction] = useState(DEFAULT_INTRODUCTION)
    const [organization, setOrganization] = useState('')
    const [profession, setProfession] = useState('')

    const [organizationOptions, setOrganizationOptions] = useState([])
    const [professionOptions, setProfessionOptions] = useState([])

    const [fileResult, setFileResult] = useState()

    const onUpload = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: result => {
                if (_.size(_.get(result, 'errors', []))) {
                    alert("Errors while trying to import file!")
                    console.error("Error while importing file:", result.errors)
                }
                setFileResult(result)

                const data = _.get(result, 'data', [])

                setOrganizationOptions(_.map(_.uniqBy(data, '03. Organisatie'), org => _.get(org, '03. Organisatie')))
                setProfessionOptions(_.map(_.uniqBy(data, '04. Functie'), prof => _.get(prof, '04. Functie')))
            }
        })
    }

    useEffect(() => {
        const data = _.get(fileResult, 'data')

        const filteredByOrgData = organization
            ? _.filter(data, d => _.get(d, '03. Organisatie') === organization)
            : data
    
        const newProfessionnalOptions = _.map(_.uniqBy(filteredByOrgData, '04. Functie'), prof => _.get(prof, '04. Functie') )

        setProfessionOptions(newProfessionnalOptions)
    }, [fileResult, organization])

    return (
        <div className='no-print'>
            <form className='no-print' style={{ display: "flex", margin: "10px auto 50px", flexDirection: "column", width: 350 }}>
                <input style={{ marginBottom: 10 }} value={name} onChange={e => setName(e.target.value)} type='text' name='name' placeholder='Naam klant' />
                <ReactQuill theme="snow" value={introduction} onChange={setIntroduction} />
                {/* <textarea style={{ marginBottom: 10, height: 250, width: 350 }} value={introduction} onChange={e => setIntroduction(e.target.value)} placeholder='Introductie' /> */}
            </form>
            <input
                type="file"
                name="file"
                accept=".csv"
                onChange={onUpload}
                style={{ display: "block", margin: "10px auto", width: 350 }}
                className="no-print"
            />
            <form className='no-print' style={{ display: "flex", margin: "10px auto 50px", flexDirection: "column", width: 350 }}>
                <select style={{ marginBottom: 10 }} defaultValue={''} onChange={e => setOrganization(e.target.value)} name="organisations">
                    <option value="">Kies een organisatie</option>
                    <>
                        {_.map(organizationOptions, option => <option key={option} value={option}>{option}</option>)}
                    </>
                </select>
                <select style={{ marginBottom: 10 }} defaultValue={''} onChange={e => setProfession(e.target.value)} name="function">
                    <option value="">Kies een functie</option>
                    <>
                        {_.map(professionOptions, option => <option key={option} value={option}>{option}</option>)}
                    </>
                </select>
            </form>
            <button
                className="no-print"
                style={{ display: "block", margin: "10px auto" }}
                onClick={() => onSubmit({name, introduction, organization, profession}, fileResult)}
            >
                Generate!
            </button>
            <button
                className="no-print"
                style={{ display: "block", margin: "10px auto" }}
                onClick={() => window.print()}
            >
                Print
            </button>
        </div>
    )
}

export default SettingsForm
