import React from 'react'
import Footer from "../components/Footer";

import WheelbarrowIcon from '../assets/img/owl_wheelbarrow.png'
import styled from 'styled-components'
import parse from 'html-react-parser';


const Page = styled.div`
  width: 210mm;
  min-height: 294mm;
  position: relative;
`

const Container = styled.div`
  margin: 0 auto;
  width: 80%;
`

const StyledTitle = styled.h1`
  ${props => props.theme.fontStyle.h1};
  margin-bottom: 20px;
`

const StyledContentText = styled.div`
  ${props => props.theme.fontStyle.body};
  white-space: pre-line;
`

const Image = styled.img`
  position: absolute;
  right: 50px;
  bottom: 100px;
  width: 150px;
`

const IntroPage = ({ page, introduction }) =>  (
  <Page>
    <Container>
      <StyledTitle>Introductie</StyledTitle>
      <StyledContentText>{parse(introduction)}</StyledContentText>
    </Container>
    <Footer page={page}/>
    <Image src={WheelbarrowIcon} alt="Wheelbarrow Icon"/>
  </Page>
)

export default IntroPage