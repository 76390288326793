import React from 'react'
import Footer from '../components/Footer'

import styled from 'styled-components'
import SingleBarChart from '../charts/SingleBarChart'

const Page = styled.div`
  width: 210mm;
  min-height: 294mm;
  position: relative;
  page-break-before: always;
`

const Container = styled.div`
  margin: 0 auto;
  width: 80%;
  position: relative;
`

const HeaderBackgroundColor = styled.div`
  background-color: #131C4E;
  width: 100%;
  margin-bottom: 30px;
  padding-top: 2px;
`

const StyledSmallHeaderText = styled.p`
  ${props => props.theme.fontStyle.euro};
  font-weight: 300;
  font-size: 18px;
  color: #fff;
  margin-left: 40px;
  display: inline;
`

const StyledSubHeaderText = styled.h3`
  ${props => props.theme.fontStyle.h3};
  font-size: 16px;
  color: #131C4E;
  text-align: center;
  height: 40px;
  margin-bottom: 0px;
`

const DescriptionPageTwo = (props) =>  (
  <Page>
    <Container>
      <HeaderBackgroundColor><StyledSmallHeaderText>taakomschrijving en ondersteuning (2/2)</StyledSmallHeaderText></HeaderBackgroundColor>
      <StyledSubHeaderText>Waar haal jij je eigen kennis en vaardigheden vandaan?</StyledSubHeaderText>
      <SingleBarChart 
        labelWidth={-6}
        respondents={props.respondents}
        categories={props.barCategories}
        series={props.barSeries}
        color={props.barColor}
        height="300px"
      />
    </Container>

    <Footer page={props.page}/>
  </Page>
)

export default DescriptionPageTwo