import {css} from "styled-components";

const colors = {
    nightA: '#131C4E',
    nightB: '#8B8DA6',
    nightC: '#E6EDFF',
    nightD: '#F1F5FF',
    nightE: '#D7D9E1',
    nightF: '#3A416B',
    kraftA: '#916547',
    kraftB: '#C0926C',
    kraftC: '#C7AD98',
    kraftD: '#F2D8C3',
    kraftE: '#CFAF90',
    tealA: '#3BA4B1',
    tealB: '#4DCBC3',
    tealC: '#A3E9E3',
    tealD: '#F2FFFE',
    salmonA: '#FC7773',
    salmonB: '#FF8E8B',
    salmonC: '#FFDBD3',
    darkGrey: '#333333',
    grey: '#9E9E9E',
    lightGrey: '#E4E4E4',
    lighterGrey: '#F3F3F3',
    offWhite: '#F6F6F6',
    white: '#FFFFFF',
    red: '#C20013',
    blue: '#293578'
}

const getStyle = (font, size) =>
    css`
        font-family: ${font.name};
        font-size: ${size}rem;
        font-weight: ${font.weight};
    `

const fonts = {
    ALSSchlangeslabBold: {
        name: 'ALSSchlangeslabBold',
        weight: 500
    },
    ALSSchlangeslabRegular: {
        name: 'ALSSchlangeslabRegular',
        weight: 300
    },
    Karla: {
        name: 'Karla',
        weight: 400
    },
    KarlaBold: {
        name: 'Karla',
        weight: 700
    },
    Plinkr: {
        name: 'Plinkr'
    }
}

const fontStyle = {
    h1: getStyle(fonts.ALSSchlangeslabBold, 1.5),
    h2: getStyle(fonts.ALSSchlangeslabBold, 1.25),
    h3: getStyle(fonts.ALSSchlangeslabBold, 1),
    h4: getStyle(fonts.ALSSchlangeslabRegular, 1),
    h5: getStyle(fonts.KarlaBold, 1),
    h6: getStyle(fonts.ALSSchlangeslabBold, 1),
    subtitle1: getStyle(fonts.KarlaBold, 1),
    subtitle2: getStyle(fonts.Karla, 1),
    body: getStyle(fonts.Karla, 0.875),
    button: getStyle(fonts.KarlaBold, 0.875),
    label: getStyle(fonts.Karla, 0.875),
    euro: getStyle(fonts.ALSSchlangeslabBold, 1),
    caption: getStyle(fonts.Karla, 0.75)
}

export const theme = {
    colors,
    fontStyle,
}