import React from 'react'
import Footer from '../components/Footer'
import _ from 'lodash'

import styled from 'styled-components'

const Page = styled.div`
  width: 210mm;
  min-height: 294mm;
  position: relative;
  page-break-before: always;
`

const Container = styled.div`
  margin: 0 auto;
  width: 80%;
  position: relative;
`

const HeaderBackgroundColor = styled.div`
  background-color: #131C4E;
  width: 100%;
  margin-bottom: 30px;
`

const StyledSmallHeaderText = styled.p`
  ${props => props.theme.fontStyle.euro};
  font-weight: 300;
  font-size: 18px;
  color: #fff;
  margin: 15px 0 15px 40px;
`

const StyledSubHeaderText = styled.h3`
  ${props => props.theme.fontStyle.h3};
  font-size: 12px;
  color: #131C4E;
  margin-bottom: 0px;
`

const StyledOrangeSubHeaderText = styled.h3`
  ${props => props.theme.fontStyle.h3};
  font-size: 16px;
  color: #FC7773;
  margin-top: 0;
  margin-bottom: 20px;
`

const StyledContentText = styled.p`
  ${props => props.theme.fontStyle.body};
  margin: 0;
  padding: 10px 14px;
  min-height: 80px;

  :nth-child(even) {
    background-color: #F5F5F5;
  }
`

const ReviewPage = (props) =>  (
  <Page>
    <Container>
      <HeaderBackgroundColor><StyledSmallHeaderText>wat de hulpverleners verder nog vertellen</StyledSmallHeaderText></HeaderBackgroundColor>
      <StyledSubHeaderText>Stel, je zou één ding kunnen veranderen als het gaat om begeleiding bij administratie en financiën.</StyledSubHeaderText>
      <StyledOrangeSubHeaderText>Wat zou dat zijn?</StyledOrangeSubHeaderText>
      {props.data && _.map(props.data, (item, index) => {
        return <StyledContentText key={index}>{item}</StyledContentText>
      })}

    </Container>

    <Footer page={props.page}/>
  </Page>
)

export default ReviewPage