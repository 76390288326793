export const parseRow = (row, ignoreSecondQuote) => {
	var insideQuote = false, entries = [], entry = []
	const splitRow = row.split('')

	splitRow.forEach((character, index) => {
		if (character === '"') {
			if (ignoreSecondQuote || splitRow[index + 1] === '"') {
				insideQuote = !insideQuote
			}
		} else {
			if (character === "," && !insideQuote) {
				entries.push(entry.join(''))
				entry = []
			} else {
				entry.push(character)
			}
		}
	})
	entries.push(entry.join('').replace(/^[\\"]+|[\\"]+$/g, ''))
	return entries
}

const parseCSV = csvData => {
	const lines = csvData.split('\n');
	const delimiter = ';';
	const records = [];

	for (let i = 0; i < lines.length; i++) {
		const line = lines[i].split(delimiter);
		const record = {};

		for (let j = 0; j < line.length; j++) {
			if (j === 0) {
				record['section'] = line[j];
			} else if (j === 1) {
				record['question'] = line[j];
			} else if (j === 2) {
				record['answerType'] = line[j];
			} else if (j === 3) {
				// remove quotes, backslashes and if it starts with a space from each answer
				//line[j].split(',').map(item => item.trim().replace(/^[\\"]+|[\\"]+$/g, ''));
				console.log(parseRow(line[j]))
				record['answers'] = parseRow(line[j]) // answers;
			} else {
				record[`Field${j + 1}`] = line[j];
			}
		}

		if (record['answerType'] !== 'open') {
			records.push(record);
		}
	}

	console.log('PARSED SURVEY CSV:', records);
	return records;
}

export default parseCSV
