import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    @font-face {
        font-family: 'ALSSchlangeslabBold';
        src: url('/assets/fonts/399485_0_0.eot');
        src: url('/assets/fonts/399485_0_0.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/399485_0_0.woff2') format('woff2'),
        url('/assets/fonts/399485_0_0.woff') format('woff'),
        url('/assets/fonts/399485_0_0.ttf') format('truetype');
        font-display: block;
    }
    @font-face {
        font-family: 'ALSSchlangeslabRegular';
        src: url('/assets/fonts/399485_1_0.eot');
        src: url('/assets/fonts/399485_1_0.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/399485_1_0.woff2') format('woff2'),
        url('/assets/fonts/399485_1_0.woff') format('woff'),
        url('/assets/fonts/399485_1_0.ttf') format('truetype');
        font-display: block;
    }
    @font-face {
        font-family: 'plinkr';
        src: url('/assets/fonts/plinkr.eot');
        src: url('/assets/fonts/plinkr.eot#iefix') format('embedded-opentype'),
        url('/assets/fonts/plinkr.ttf') format('truetype'),
        url('/assets/fonts/plinkr.woff') format('woff'),
        url('/assets/fonts/plinkr.svg#plinkr') format('svg');
        font-display: block;
    }
`