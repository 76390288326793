import React from 'react'
import Footer from '../components/Footer'

import styled from 'styled-components'
import DonutChart from '../charts/DonutChart'
// import DoubleBarChart from '../charts/DoubleBarChart'
import SingleBarChart from '../charts/SingleBarChart'

const Page = styled.div`
  width: 210mm;
  min-height: 294mm;
  position: relative;
  page-break-before: always;
`

const Container = styled.div`
  margin: 0 auto;
  width: 80%;
`

const HeaderBackgroundColor = styled.div`
  background-color: #131C4E;
  width: 100%;
  margin-bottom: 50px;
`

const StyledSmallHeaderText = styled.p`
  ${props => props.theme.fontStyle.euro};
  font-weight: normal;
  color: #fff;
  margin: 15px 0 15px 40px;
`

const StyledSubHeaderText = styled.h3`
  ${props => props.theme.fontStyle.h3};
  margin-top: 50px;
  margin-bottom: 30px;
  text-align: center;
  color: #131C4E;
`

// const FlexContainer = styled.div`
//   display: flex;
//   align-items: center;
//   flex-direction: row;
//   margin: 10px 0 0;
// `

// const StyledContentText = styled.p`
//   ${props => props.theme.fontStyle.body};
//   margin: 0;
//   font-size: 12px;
// `

const getSortIndex = category => {
  if (category === '0% - 25%') return 1
  if (category === '25% - 50%') return 2
  if (category === '50% - 75%') return 3
  if (category === '75% - 100%') return 4
}

const TargetAudiencePage = (props) => {
  const parsedDonutData = props.donutCategories.map((category, index) => ({
    category: category,
    value: props.donutSeries[index],
    sort: getSortIndex(category)
  }))

  const sortedDonutData = parsedDonutData.sort((a, b) => a.sort - b.sort)

  const donutCategories = sortedDonutData.map(data => data.category)
  const donutSeries = sortedDonutData.map(data => data.value)

  return (
    <Page>
      <Container>
        <HeaderBackgroundColor><StyledSmallHeaderText>doelgroep</StyledSmallHeaderText></HeaderBackgroundColor>
        <StyledSubHeaderText>Welke kenmerken zijn van toepassing op de mensen die je begeleidt?</StyledSubHeaderText>
        <SingleBarChart labelWidth={-44} respondents={props.respondents} categories={props.barCategories} series={props.data} height={500} colors={['#FC7773']}/>

        <StyledSubHeaderText>Welk deel van alle mensen die je begeleidt kampt met financiële problemen?</StyledSubHeaderText>
        <DonutChart donutCategories={donutCategories} donutSeries={donutSeries} isCumulative height={200}/>

      </Container>

      <Footer page={props.page}/>
    </Page>
  )
}

export default TargetAudiencePage