import React from 'react'
import _ from 'lodash'
import Footer from '../components/Footer'

import styled from 'styled-components'
import SingleBarChart from '../charts/SingleBarChart'
import DonutChart from '../charts/DonutChart'
import ColumnChart from '../charts/ColumnChart'

const Page = styled.div`
  width: 210mm;
  min-height: 294mm;
  position: relative;
  page-break-before: always;
`

const Container = styled.div`
  margin: 0 auto;
  width: 80%;
`

const HeaderBackgroundColor = styled.div`
  background-color: #131C4E;
  width: 100%;
  margin-bottom: 50px;
`

const StyledSmallHeaderText = styled.p`
  ${props => props.theme.fontStyle.euro};
  font-weight: normal;
  font-size: 18px;
  color: #fff;
  margin: 15px 0 15px 40px;
`

const StyledSubHeaderText = styled.h3`
  ${props => props.theme.fontStyle.h3};
  font-size: 18px;
  margin-bottom: 0px;
  text-align: center;
  color: #131C4E;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ChartContainer = styled.div`
  width: 45%;
  max-height: 400px;
  margin-bottom: 50px;
`

const StyledContentText = styled.p`
  ${props => props.theme.fontStyle.body};
  margin: 0;
`

const getResultsForRow = (results, columnName) =>
  _.map(results, result => _.get(result, columnName))

const FinanGroupChart = ({ results, id }) => {
  const timeOpinionCount = _.countBy(getResultsForRow(results, '13. Mening tijdsbesteding'))
  // const totalOpinions = _.reduce(_.values(timeOpinionCount), (prev, next) => prev + next, 0)

  const colors = ['#3BA4B1', '#3A416B', '#C0926C', '#916547']
  const labels = ['Te weinig', 'Precies goed', 'Te veel', 'Geen mening']

  const finalData = _.map(labels, label => {
    const key = _.findKey(timeOpinionCount, (value, key) => _.lowerCase(key).includes(_.lowerCase(label)))
    return _.get(timeOpinionCount, key, 0)
  })

  return (
    <ColumnChart /*respondents={totalOpinions}*/ categories={labels} series={finalData} colors={colors[id]}  />
  )
}

const MotivationPage = ({page, respondents, results, ...props}) =>  {
  const finanTimeGroup = _.groupBy(results, '12. Tijdsbesteding')
  const finanTimeList = _.sortBy(_.map(_.keys(finanTimeGroup), key => ({key, value: finanTimeGroup[key]})), 'key')

  const parsedBarCategories = _.map(props.barCategories, item => item.startsWith('Anders:') ? 'Anders' : item)

  const labels = ['0% - 25%', '25% - 50%', '50% - 75%', '75% - 100%']

  const finalData = _.map(labels, label => {
    const key = _.findKey(finanTimeList, value => value.key === label)
    return {
      [label]: _.get(finanTimeList[key], 'value') || 0
    }
  })

  return (
    <Page>
      <Container>
        <HeaderBackgroundColor><StyledSmallHeaderText>drijfveren</StyledSmallHeaderText></HeaderBackgroundColor>
        <FlexContainer>
          <ChartContainer>
            <StyledContentText>Welk deel van je tijd besteed je met of voor klanten aan administratie en financien?</StyledContentText>
            <SingleBarChart noWidth respondents={respondents} categories={labels} series={_.map(finalData, item => _.size(_.values(item)[0]))} height={350} colors={['#3BA4B1', '#3A416B', '#C0926C', '#916547']} />
          </ChartContainer>
          <ChartContainer>
            <StyledContentText>Wat vind je hiervan, in verhouding tot andere leefgebieden?</StyledContentText>
            <>
              {_.map(finalData, (item, index) => <FinanGroupChart id={index} key={_.keys(item)[0]} results={_.values(item)[0]} />)}
            </>
          </ChartContainer>
        </FlexContainer>

        <StyledSubHeaderText style={{textAlign: 'center', marginTop: 0}}>Met welke reden(en) begeleid je je klanten bij administratie en geldzaken?</StyledSubHeaderText>
        <SingleBarChart labelWidth={2} respondents={respondents} categories={parsedBarCategories} series={props.barSeries} />

        <StyledSubHeaderText style={{textAlign: 'center', marginTop: 20, marginBottom: 30 }}>Stelling: financiële problemen staan begeleiding op andere leefdomeinen in de weg.</StyledSubHeaderText>
        <DonutChart donutCategories={props.donutCategories} donutSeries={props.donutSeries} height="180" />
      </Container>

      <Footer page={page}/>
    </Page>
  )
}

export default MotivationPage