import Chart from 'react-apexcharts'
import React from 'react'

const SingleBarChart = (props) => {
  const options = {
      chart: {
        type: 'bar',
        height: 150,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: props.colors
        }
      },
      colors: props.colors || props.color || '#916547',
      dataLabels: {
        position: 'right',
        offsetX: 10,
        offsetY: 0,
        enabled: true,
        textAnchor: 'end',
        style: {
          fontSize: '10px',
          fontFamily: 'Karla',
          fontWeight: 800,
        },
        formatter: function (val) {
          return val + "%";
        },
      },
      xaxis: {
        show: true,
        categories: props.categories || [],
        labels: {
          trim: false,
          show: false,
          maxWidth: 1000,
        },
        formatter: function(str) {
          const n = 45;
          return str.length > n ? str.substr(0, n - 1) + '...' : str
        }
      },
      yaxis: {
        max: 100,
        show: true,
        showAlways: true,
        labels: {
            show: true,
            align: 'left',
            offsetY: 2,
            offsetX: props.labelWidth || -20,
            minWidth: props.noWidth ? 100 : 270,
            maxWidth: props.noWidth ? 100 : 270,
            style: {
                fontSize: '10px',
                fontFamily: 'Karla',
                fontWeight: 800,
            },
            formatter: function(str) {
              const n = 55
              return str.length > n ? str.substr(0, n - 1) + '...' : str
            }
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    }

    const percentageSeries = []
    const absoluteSeries = []

    const toPercentages = numbers => {
      numbers.forEach(number => percentageSeries.push(Math.round(number/props.respondents*100)))
    }

    const absolutePercentages = numbers => {
      const sum = numbers.reduce((a, b) => a + b, 0)
      numbers.forEach(number => absoluteSeries.push(Math.round(number/sum*100)))
    }

    props.series && toPercentages(props.series)
    props.series && absolutePercentages(props.series)

    const series = [{
      data: props.isAbsolute ? absoluteSeries : percentageSeries 
    }]

  return (
      props.categories &&
          <Chart options={options} series={series} type="bar" width="100%" height={props.height || "150px"}/>
  )

}

export default SingleBarChart