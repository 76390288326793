import React from 'react'
import _ from 'lodash'
import Logo from '../assets/img/logo.png'
import Banner from '../assets/img/banner.png'
import styled from 'styled-components'

const Page = styled.div`
  width: 210mm;
  min-height: 294mm;
`

const Header = styled.div`
  padding: 0 0 50px;
  margin: 0 auto;
  width: 80%;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: ;center;
  flex-direction: row;
  margin: 40px auto 0;
  width: 80%;
`

const StyledTitle = styled.h1`
  ${props => props.theme.fontStyle.h1};
  color: #131C4E;
  margin-top: 100px;
  margin-bottom: 20px;
`

const StyledContentText = styled.p`
  ${props => props.theme.fontStyle.body};
`

const StyledBoldContentText = styled.p`
  ${props => props.theme.fontStyle.body};
  font-weight: bold;
`

const StyledOrangeContentText = styled.p`
  color: #FC7773;
  ${props => props.theme.fontStyle.body};
`

const getSubtitle = (org, prof) => {
  if (org && prof) {
    return `${org}: ${prof}`
  }

  if (org) {
    return org
  }

  if (prof) {
    return prof
  }

  return 'Alle respondenten'
}

const FrontPage = ({ respondents, dates, clientName, organizationName, professionName }) => {
  const subtitle = getSubtitle(_.capitalize(organizationName), professionName)

  return (
    <Page>
      <Header>
        <img width="100px" alt="Logo" src={Logo} />
        <StyledTitle>Inzichten startmeting {clientName}</StyledTitle>
        <StyledOrangeContentText>{subtitle}</StyledOrangeContentText>
      </Header>
      <img width="100%" alt="Banner" src={Banner} />

      <FlexContainer>
        <div>
          <StyledBoldContentText>Aantal respondenten</StyledBoldContentText>
          <StyledBoldContentText>Looptijd meting</StyledBoldContentText>
        </div>
        <div style={{ marginLeft: 50 }}>
          <StyledContentText>{respondents}</StyledContentText>
          <StyledContentText>{dates}</StyledContentText>
        </div>
      </FlexContainer>
    </Page>
  )
}

export default FrontPage