import { useReducer } from 'react'
import _ from 'lodash'

const initialState = {
    doubleBar: [],
    targetDonut: [],
    motivationHorizontalBar: [],
    motivationDonut: [],
    motivationBar: [],
    activityOneBar: [],
    activityTWoBar: [],
    activityThreeBar: [],
    activityFourBar: [],
    activityFiveBar: [],
    activitySixBar: [],
    descriptionOneDonut: [],
    descriptionTwoDonut: [],
    descriptionThreeDonut: [],
    descriptionFourDonut: [],
    descriptionBar: [],
}

const reducer = (state, action) => {
    const type = _.get(action, 'type')
    const data = _.get(action, 'data', [])

    switch (type) {
        case 'MOTIVATION_HORIZONTAL_BAR':
            const motivationHorizontalBar = _.get(action, 'motivationHorizontalBar', [])
            return {
                ...state,
                motivationHorizontalBar: Object.keys(motivationHorizontalBar)
                .map((key) => {
                    return motivationHorizontalBar[key]
                }),
            }
        case 'DESCRIPTION_ONE_DONUT':
            const descriptionOneDonut = _.get(action, 'descriptionOneDonut', [])
            return {
                ...state,
                descriptionOneDonut: Object.keys(descriptionOneDonut)
                .map((key) => {
                    return descriptionOneDonut[key]
                }),
            }
        case 'DESCRIPTION_TWO_DONUT':
            const descriptionTwoDonut = _.get(action, 'descriptionTwoDonut', [])
            return {
                ...state,
                descriptionTwoDonut: Object.keys(descriptionTwoDonut)
                .map((key) => {
                    return descriptionTwoDonut[key]
                }),
            }
        case 'DESCRIPTION_THREE_DONUT':
            const descriptionThreeDonut = _.get(action, 'descriptionThreeDonut', [])
            return {
                ...state,
                descriptionThreeDonut: Object.keys(descriptionThreeDonut)
                .map((key) => {
                    return descriptionThreeDonut[key]
                }),
            }
        case 'DESCRIPTION_FOUR_DONUT':
            const descriptionFourDonut = _.get(action, 'descriptionFourDonut', [])
            return {
                ...state,
                descriptionFourDonut: Object.keys(descriptionFourDonut)
                .map((key) => {
                    return descriptionFourDonut[key]
                }),
            }
        default:
            return (type && data) ? {
                ...state,
                [type]: Object.keys(data).map(key => data[key]),
            } : state
    }
}

const useChartData = () => {
    const [state, setState] = useReducer(reducer, initialState)
    
    return [{ setState }, state]
}

export default useChartData