import React from 'react'
import styled from 'styled-components'

const StyledSubtitleText = styled.p`
  ${props => props.theme.fontStyle.body};
  position: absolute;
  left: 44%;
  right: 50%;
  bottom: 100px;
  width: 120px;
`

const Footer = (props) => (
  <StyledSubtitleText>{props.page}</StyledSubtitleText>
)

export default Footer